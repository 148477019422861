import { useAuthStore } from '@/store/auth'
import { QUERY_STRING_KEYS } from '@/constants/query-string-keys'
import type { LoginPendingAction } from '@/types/member'

import { GtmEvent } from '@/types/gtm'

export function useAuth() {
  tryOnMounted(() => {
    const authStore = useAuthStore()
    authStore.getAuth()
  })
}

export function useLogin() {
  const authStore = useAuthStore()
  const router = useLocaleRouter()
  const route = useRoute()
  const { fetchCollectionApi } = useCollection()
  const loginPendingAction = useLoginPendingActionCookie()

  const prepareForLogin = (pendingAction: LoginPendingAction) => {
    loginPendingAction.value = pendingAction
  }

  const checkAndExecutePendingAction = () => {
    if (!loginPendingAction.value) return

    const { type, payload } = loginPendingAction.value

    switch (type) {
      case 'addFavorite':
        fetchCollectionApi({ id: payload.id, type: payload.type, isFavorited: false })
        break
      default:
        break
    }

    loginPendingAction.value = null
  }

  const handleLoginSuccess = async (redirectUrl?: string) => {
    const _redirectUrl = redirectUrl || route.query[QUERY_STRING_KEYS.REDIRECT_URL]

    checkAndExecutePendingAction()
    await authStore.getAuth()
    if (authStore.isLoginModalShown) {
      authStore.closeLoginModal()
    }
    if (typeof _redirectUrl === 'string' && _redirectUrl) {
      router.replace(_redirectUrl)
    }

    useNuxtApp().$trackEvent({
      event: GtmEvent.ACCOUNT_LOGGED_IN,
      payload: {
        'Registration Type': 'Email',
      },
    })
  }

  return { prepareForLogin, handleLoginSuccess }
}
