import { putFavoriteById, deleteFavoriteById, putCollectionById, deleteCollectionById } from '@/service/member'
import { useAlertStore } from '@/store/alert'
import { useBranchStore } from '@/store/branch'
import { useProductStore } from '@/store/product'

type FetchCollectionApiArgs = {
  id: number
  type: 'product' | 'branch'
  isFavorited: boolean
  saleRegionId?: number
}

export function useCollection() {
  let controller = new AbortController()

  const alertStore = useAlertStore()
  const productStore = useProductStore()
  const branchStore = useBranchStore()

  const { regionId } = useRegion()

  const { t } = useI18n()

  const fetchCollectionApi = async ({ id, type, isFavorited, saleRegionId }: FetchCollectionApiArgs) => {
    const isProductType = type === 'product'
    const theStore = isProductType ? productStore : branchStore
    const putFn = isProductType ? putCollectionById : putFavoriteById
    const deleteFn = isProductType ? deleteCollectionById : deleteFavoriteById
    const apiFn = isFavorited ? deleteFn : putFn
    const successKey = (() => {
      if (isProductType) {
        return isFavorited ? 'my_favorites_remove_product_success' : 'my_favorites_add_product_success'
      }

      return isFavorited ? 'my_favorites_remove_merchant_success' : 'my_favorites_add_merchant_success'
    })()

    controller.abort()
    controller = new AbortController()
    theStore.setValueToMapByKey(id, 'is_favorite', (prevState: boolean) => !prevState)

    try {
      await apiFn(id, { signal: controller.signal, body: { sale_region_id: saleRegionId || regionId.value } })
      alertStore.show({ message: t(successKey), type: 'favorite', iconName: 'heart-line' })
    } catch (error: any) {
      if (error?.options?.signal.aborted) return

      theStore.setValueToMapByKey(id, 'is_favorite', (prevState: boolean) => !prevState)
    }
  }

  return { fetchCollectionApi }
}
